import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Stack, Button, IconButton, Typography, Switch, Alert, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { FarmMap } from "@agricircle/shared/farms/components";
import { CopyRefButton } from "@agricircle/shared/widgets";
import { selectFarm } from "@agricircle/shared/farms/redux";
import { usePastureApi } from "../../hooks/pasture";
import { apiUrl, usePrompt } from "@agricircle/shared/hooks";
import { displayInfoMessage } from "@agricircle/shared/redux";

const FIELD_COLORS = [
    { min: 0.8, max: 1.0, color: "#355e3b", id: "A" },
    { min: 0.6, max: 0.8, color: "#3e9b0a", id: "B" },
    { min: 0.4, max: 0.6, color: "#bab86c", id: "C" },
    { min: 0.2, max: 0.4, color: "#f8dE7E", id: "D" },
    { min: 0.0, max: 0.2, color: "#5c4033", id: "E" },
    { min: -2, max: -1, color: "darkgrey", id: "none" },
];

const UNDEF_PROPS = { weight: 0.5, color: "white", dashArray: "3, 3" };
const TEMPLATE_PROPS = { weight: 0.5, color: "white", dashArray: "" };
const CLOUDY_PATTERNS = FIELD_COLORS.map((c) => `
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8">
    <defs>
        <pattern id="${c.id}-pattern" patternUnits="userSpaceOnUse" width="8" height="8">
        <rect width="100%" height="100%" fill="${c.color}" />
        <path d="M 0 0 L 8 8" stroke="gray" stroke stroke-width="1" />
        </pattern>
    </defs>
    </svg>
`);

export const Latest = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const prompt = usePrompt();
    const farm = useSelector(selectFarm);
    const pastureApi = usePastureApi();
    const farmMapRef = useRef();
    const selectedFields = useRef(null);
    const [_, setForceUpdate] = useState(0);
    const [latestJob, setLatestJob] = useState();
    const [transparency, setTransparency] = useState(true);
    const [latestData, setLatestData] = useState();
    const [date, setDate] = useState();
    const [fieldOptions, setFieldOptions] = useState();
    const [refreshTimeout, setRefreshTimeout] = useState(null);
    const fields = farm?.fields;

    useEffect(() => {
        if (refreshTimeout)
            return () => clearTimeout(refreshTimeout);
    }, [refreshTimeout]);

    useEffect(() => {
        if (!farmMapRef.current || !fields.length) return;

        const svgDefsList = CLOUDY_PATTERNS.map(pattern => {
            const div = document.createElement("div");
            div.innerHTML = pattern;
            const svgElem = div.querySelector("svg");
            return svgElem.querySelector("defs");
        });
        // Append <defs> to the map's SVG container
        setTimeout(() => {
            const svg = document.querySelector(".leaflet-container svg");
            if (svg) {
                svgDefsList.forEach(svgDefs => svg.appendChild(svgDefs));
            }
        }, 1000);
    }, [Boolean(farmMapRef.current), Boolean(fields.length)]);

    useEffect(() => {
        if (!farm?.id || latestData) return;

        selectedFields.current = null;
        pastureApi.getLatest(farm.id, data => {
            const maxDate = Object.values(data).reduce((maxD, cur) => cur.date > maxD ? cur.date : maxD, "");
            const deltaDays = Math.floor((new Date() - new Date(maxDate)) / (1000 * 60 * 60 * 24));
            setDate({ maxDate, upToDate: deltaDays < 14 });
            setLatestData(data);
        });
    }, [farm?.id, Boolean(latestData)]);

    useEffect(() => {
        if (!fields) return;

        const options = {};
        fields.forEach(field => options[field.id] = fieldStatus(latestData?.[field.id], transparency));
        setFieldOptions(options);
    }, [Boolean(fields), Boolean(latestData), transparency]);

    if (!fields) return null;

    function fieldStatus(latestFieldData) {
        if (!latestFieldData) return transparency ? { ...UNDEF_PROPS, fillOpacity: 0.2 } : UNDEF_PROPS;

        const avg = latestFieldData.data.avg;
        const entry = FIELD_COLORS.find(fc => avg >= fc.min);
        const dateProps = latestFieldData.date < date.maxDate ? { weight: 0.75, dashArray: "2, 6" } : {};
        const props = { ...TEMPLATE_PROPS, fillColor: entry.color, ...dateProps, fillOpacity: transparency ? 0.65 : 1 };
        if (latestFieldData.data.cloud && latestFieldData.data.cloud > 30)
            props.fillColor = `url(#${entry.id}-pattern)`;
        return props;
    }

    async function handleUpdate(confirm) {
        const result = await pastureApi.updateLatest(farm.id, confirm);
        if (result.isError && result.status !== 422) return;

        if (result.data?.field_count) {
            const validator = (v) => (v === t("shared:confirm-billing-prompt")) ? null : "";
            prompt(
                t("shared:billable-operation"),
                () => handleUpdate(false),
                { label: t("shared:confirm-billing-text", { "count": result.data.field_count, "area": result.data.total_area }), action: t("shared:btn-proceed"), validator }
            );
            return;
        }

        const jobId = result.data;
        if (!jobId) {
            dispatch(displayInfoMessage(t("no-changes")));
            setLatestJob(null);
        } else {
            setLatestJob(jobId);
            startRefreshTimeout();
        }
    }

    function toggleTransparency() {
        setTransparency(t => !t);
    }

    function handleRefresh() {
        pastureApi.jobStatus(latestJob, status => {
            if (status == 202) {
                dispatch(displayInfoMessage(t("in-progress")));
                startRefreshTimeout();
            } else {
                setLatestJob(null);
                setLatestData(null);
                setForceUpdate(forceUpdate => forceUpdate + 1);
            }
        });
    }

    function startRefreshTimeout() {
        const timeout = setTimeout(() => {
            setRefreshTimeout(null);
        }, 30000);
        setRefreshTimeout(timeout);
    }

    function handleFieldClick(fieldId) {
        const selected = selectedFields.current?.[0]?.field;
        if (!fieldId && !selected || fieldId === selected?.id) {
            return false;
        }
        const field = fieldId && fields.find(f => f.id === fieldId);
        selectedFields.current = fieldId ? [{ field }] : null;
        setLatestData(latestData => {
            if (latestData?.[fieldId])
                loadFieldDetails();
            else
                setForceUpdate(forceUpdate => forceUpdate + 1);
            return latestData;
        });
        return false;
    }

    function loadFieldDetails() {
        if (selectedFields.current) {
            const selected = selectedFields.current[0];
            const overlayUrl = `${apiUrl}/fields/${selected.field.id}/field_indices/pasture.png`;
            const mapBbox = selected.field.map_metadata.map_bbox;
            const bounds = [[mapBbox[0][1], mapBbox[0][0]], [mapBbox[1][1], mapBbox[1][0]]];
            selected.overlay = { bounds, url: overlayUrl };
            setForceUpdate(f => f + 1);
        }
    }

    const singleSelected = selectedFields.current?.length === 1 ? selectedFields.current[0] : null;

    return (<Stack sx={{ width: "100%" }}>
        <Stack direction="row">
            <FarmMap
                ref={farmMapRef}
                farm={farm}
                fields={fields}
                fieldOptions={fieldOptions}
                selectedFields={selectedFields.current ? selectedFields.current.map(f => f.field.id) : null}
                fieldOverlays={selectedFields.current ? selectedFields.current.filter(f => Boolean(f.overlay)).map(f => f.overlay) : null}
                onFieldClick={handleFieldClick}
            />
            <Stack sx={{ width: "400px", backgroundColor: "#fffad350", padding: "10px" }} spacing={3}>
                <div>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        {date?.maxDate
                            ? (<Typography color={date.upToDate ? undefined : "error"}>
                                {(new Date(date.maxDate)).toLocaleDateString("en-us", { month: "short", day: "numeric" })}
                            </Typography>)
                            : (date?.maxDate === "" || null) && (<Typography>
                                {t("no-info")}
                            </Typography>)}
                        {latestJob === null
                            ? <Typography>{t("up-to-date")}</Typography>
                            : (<div>
                                <Button onClick={handleUpdate} disabled={latestJob !== undefined}>{t("shared:update-btn")}</Button>
                                {(latestJob || null) && (<IconButton
                                    onClick={handleRefresh}
                                    color="primary"
                                    disabled={refreshTimeout}
                                >
                                    <RefreshIcon sx={!refreshTimeout ? {} : {
                                        animation: "spin 5s linear infinite",
                                        "@keyframes spin": {
                                            "0%": {
                                                transform: "rotate(0deg)",
                                            },
                                            "100%": {
                                                transform: "rotate(360deg)",
                                            },
                                        },
                                    }}
                                    />
                                </IconButton>)}
                            </div>)
                        }
                    </Stack>
                    <Stack direction="row" alignItems="center">
                        <Switch checked={transparency} onChange={toggleTransparency} edge="start" />
                        <Typography>{t("transparent")}</Typography>
                    </Stack>
                </div>
                <Legend />
                {(singleSelected || null) && <FieldInfo
                    field={singleSelected.field}
                    info={latestData?.[singleSelected.field.id] || {}}
                    avgDate={date}
                />}
            </Stack>
        </Stack>
    </Stack>);
};


const Legend = () => {
    const { t } = useTranslation();

    return (<Stack spacing={1}>
        {FIELD_COLORS.map((r, idx) => <Typography
            key={`l-${idx}`}
            component="div"
            sx={{
                color: "white",
                textAlign: "center",
                backgroundColor: r.color,
                fontWeight: "bold",
                fontSize: 16,
                verticalAlign: "middle",
                pt: 1,
                pb: 1
            }}
        >
            {t("class-" + r.id)}
        </Typography>)}
    </Stack>);
};

const FieldInfo = ({ field, info, avgDate }) => {
    const { t } = useTranslation();

    return (<Stack sx={{ pl: 2, pr: 2 }} alignItems="center">
        <Tooltip
            title={<span><CopyRefButton info={field.id} />{field.id}</span>}
            placement="bottom-start"
            enterDelay={1000}
            leaveDelay={1000}
            arrow
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: { offset: [0, -14] },
                        }
                    ]
                }
            }}
        >
            <Typography variant="h6" noWrap>{field.name}</Typography>
        </Tooltip>

        <Typography variant="caption">{field.area} ha</Typography>
        {!info.date
            ? (<Alert severity="info">{t("no-data")}</Alert>)
            : (info.date !== avgDate?.maxDate || null) && <Typography variant="caption" color="error">{info.date}</Typography>}
        {(info.data?.cloud || null) && (
            info.data.cloud <= 30
                ? <Typography>{t("cloud-index", { value: Math.round(info.data.cloud) })}</Typography>
                : <Alert severity="warning">{t("cloud-index", { value: Math.round(info.data.cloud) })}</Alert>
        )}
    </Stack>);
};
